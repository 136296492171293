import axios from 'axios';

export const BASE_URL = 'https://rqsv2.nassa.site/'; // Live server
//export const BASE_URL = 'http://127.0.0.1:8000/'; // local server


const getToken = () => {
    const url = document.URL;

    const token = url.split("token=")[1];

    if (token) {
        localStorage.setItem("token", token);
    }
    return token;
};

const token = getToken();

//const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vMTI3LjAuMC4xOjgwMDAvYXV0aCIsImlhdCI6MTY5OTM0OTAwOSwiZXhwIjoxNzAwNTU4NjA5LCJuYmYiOjE2OTkzNDkwMDksImp0aSI6IllVTXg1Z1IzaDlycE1Ea20iLCJzdWIiOiIyMiIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.ql03xNDnjEuB2t9ZxX5V4P_uddFOwLYr-QJEa1W3XIc"

console.log("token from local storage ------->>> ", token);


export default axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
});

export const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
}

export async function Post(apiEndPoint, data) {
    return await axios.post(`${BASE_URL}${apiEndPoint}`, data, {
        headers: headers
    });

}

export async function Get(apiEndPoint) {
    return await axios.get(`${BASE_URL}${apiEndPoint}`, {
        headers: headers
    });
}

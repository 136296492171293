import React from "react";
import { Table } from "./Components/Table";


function App() {

  const [token, setToken] = React.useState('');

  React.useEffect(() => {
    const url = document.URL;
    console.log("URL ------->>> ", url)
    const token = url.split("token=")[1];
    console.log("Token ------->>> ", token)
    setToken(token);
    console.log("token from url ------->>> ", token);

    if (token) {
      localStorage.setItem("token", token);
    }
  }, []);

  return (
    <div className="App">
      {
        token ? <Table /> : <h1 style={{ marginTop: '20%' }}>Token not found</h1>
      }
       {/* <Table /> */}
    </div>
  );
}

export default App;
